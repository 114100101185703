/* src/components/ServiceCard/ServiceCard.css */
.service-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    max-width: 800px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .service-card__image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 20px;
  }
  
  .service-card__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .service-card__title {
    font-size: 1.8rem;
    margin: 0 0 10px;
    color: #333;
  }
  
  .service-card__description {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .service-card {
      flex-direction: column;
      text-align: center;
    }
  
    .service-card__image {
      margin-right: 0;
      margin-bottom: 15px;
      width: 100%;
      height: auto;
      max-width: 300px;
    }
  
    .service-card__content {
      align-items: center;
    }
  }
  