.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #282c34;
  color: white;
  position: relative;
  z-index: 1000;
}

.logo {
  font-size: 1.5rem;
}

.nav-links {
  display: flex;
  gap: 1rem;
  list-style: none;
}

.nav-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #61dafb;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggle .bar {
  height: 2px;
  width: 25px;
  background-color: white;
  margin: 4px 0;
  transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
  .nav-links {
      position: absolute;
      top: 60px;
      right: 0;
      background-color: rgba(40, 44, 52, 0.95);
      flex-direction: column;
      width: 100%;
      max-width: 300px;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      opacity: 0;
      padding: 1rem;
      border-radius: 0 0 8px 8px;
  }

  .nav-links.open {
      transform: translateX(0);
      opacity: 1;
  }

  .menu-toggle {
      display: flex;
  }

  .menu-toggle .bar {
      background-color: #61dafb;
  }

  /* Désactiver le défilement du corps lors de l'ouverture du menu */
  body.menu-open {
      overflow: hidden;
  }
}
