/* Styles généraux */
body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f5f5f5; /* Couleur de fond légèrement gris clair */
  color: #333333;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Container principal */
.home-container {
  padding: 20px;
  color: #333;
}

/* Section générique */
.section {
  margin-bottom: 60px;
  padding: 20px;
  background: #ffffff; /* Blanc */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.section:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Section de bienvenue */
.welcome-section {
  background-color: #4a90e2; /* Bleu doux */
  color: #ffffff; /* Blanc pour le texte */
  text-align: left; /* Alignement à gauche pour le texte */
  padding: 80px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  display: flex; /* Utilisation de flexbox pour l'agencement */
  align-items: center;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.welcome-section:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
}

.welcome-section-content {
  max-width: 60%; /* Limite la largeur du contenu textuel */
}

.welcome-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ffffff; /* Blanc pour le titre */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.welcome-section p {
  font-size: 1.3em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #f1f1f1; /* Blanc cassé */
}

.welcome-section-image {
  max-width: 35%; /* Limite la largeur de l'image */
}

.welcome-section img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Ajout de l'animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.welcome-section h2, .welcome-section p {
  animation: fadeIn 2s ease-in-out;
}

/* Styles pour les autres sections et éléments */
h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  color: #4a90e2;
}

.carousel-section h2 {
  margin-bottom: 30px;
}

/* Styles pour le carrousel des nouveautés */
.carousel-section .carousel-root {
  max-width: 1200px;
  margin: 0 auto;
}

.carousel-slide {
  height: 400px; /* Définir une hauteur cohérente pour les slides */
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Assurer que l'image remplit le conteneur sans être déformée */
  border-radius: 8px;
}

p {
  font-size: 1.2em;
  line-height: 1.6;
  text-align: center;
}

.services-section .carousel-container {
  padding: 20px;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Mise à jour du style pour les icônes de service */
.service-icon {
  width: 100px; /* Augmente la taille de l'image */
  height: 100px;
  margin-bottom: 15px;
  object-fit: contain; /* S'assure que l'image est bien contenue dans son conteneur */
}

/* Amélioration du style de la carte de service */
.service-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center; /* Aligne les éléments au centre */
}

.service-card h3 {
  margin-top: 15px; /* Ajoute de l'espace entre l'image et le titre */
  font-size: 1.5em; /* Agrandit légèrement le titre */
}

.service-card p {
  font-size: 1.1em; /* Augmente la taille de la description */
  color: #666;
}

/* Ajout d'un style responsive pour des petits écrans */
@media (max-width: 768px) {
  .service-card {
    padding: 15px;
  }
  
  .service-icon {
    width: 80px; /* Réduit la taille de l'image sur les petits écrans */
    height: 80px;
  }

  .service-card h3 {
    font-size: 1.3em; /* Réduit légèrement la taille du titre sur mobile */
  }

  .service-card p {
    font-size: 1em;
  }

  .carousel-slide {
    height: 250px;
  }

  .carousel-section .carousel-root {
    max-width: 100%;
  }
}


.partners {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.partners img {
  max-width: 120px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.partners img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel .legend {
  background: rgba(0, 0, 0, 0.5) !important;
  color: white !important;
  font-size: 1.2em !important;
  padding: 10px !important;
}

.products-carousel-section .btn {
  display: block;
  width: 200px;
  margin: 20px auto 0;
  text-align: center;
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.products-carousel-section .btn:hover {
  background-color: #357ab9;
  transform: translateY(-5px);
}

.products-carousel-section .carousel-root {
  width: 80%;
  margin: 0 auto;
}

.products-carousel-section .carousel .slide {
  height: 90%;
}

.products-carousel-section .carousel .slide img {
  max-width: 300px;
  max-height: 200px;
  object-fit: contain;
  margin: 0 auto;
}

/* Media Queries pour les petits écrans */
@media (max-width: 768px) {
  .welcome-section {
    flex-direction: column; /* Disposition en colonne pour mobile */
    text-align: center; /* Centrer le texte */
  }

  .welcome-section-content {
    max-width: 100%;
  }

  .welcome-section-image {
    max-width: 100%;
    margin-top: 20px; /* Ajoute un espace entre le texte et l'image */
  }
}
