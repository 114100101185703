/* Importation de la police FontAwesome pour les icônes */
@import '~@fortawesome/fontawesome-free/css/all.css';

.products-section {
  padding: 2rem;
  background-color: #f8f9fa; /* Couleur de fond douce */
}

.search-input {
  padding: 0.75rem 1rem;
  margin-bottom: 1.5rem;
  width: calc(100% - 20%);
  max-width: 600px;
  font-size: 1rem;
  border-radius: 25px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: all 0.3s ease;
  background-color: #ffffff; /* Fond blanc pour l'input */
}

.search-input:focus {
  border-color: #007BFF;
  box-shadow: 0 2px 5px rgba(0, 123, 255, 0.5);
}

.product-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px; /* Bords arrondis */
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #ffffff; /* Fond blanc pour les cartes */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre subtile */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Effet de survol */
}

.card:hover {
  transform: translateY(-5px); /* Légère élévation au survol */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée au survol */
}

.card img {
  width: 100%;
  height: auto;
  max-width: 150px;
  margin-bottom: 1rem;
  border-radius: 4px; /* Bords arrondis pour les images */
}

.card h3 {
  font-size: 1.25rem; /* Taille de police du nom du produit */
  margin: 0.5rem 0; /* Espacement */
}

.card p {
  font-size: 1rem;
  margin: 0.25rem 0; /* Espacement */
}

.card .price {
  font-size: 1.1rem;
  font-weight: bold;
  color: #007BFF; /* Couleur bleue pour les prix */
  margin-top: 0.5rem; /* Espacement au-dessus du prix */
}

.card .price-license {
  color: #28a745; /* Couleur verte pour le prix de licence */
}

.card .price-subscription {
  color: #dc3545; /* Couleur rouge pour le prix d'abonnement */
}

@media (min-width: 768px) {
  .products-section {
    padding: 2rem 3rem;
  }
  
  .product-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    width: calc(50% - 1rem);
  }

  .search-input {
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  .products-section {
    padding: 2rem 10%;
  }

  .card {
    width: calc(33.333% - 1rem);
  }
}

/* Styles pour le message de chargement */
.loading-message {
  font-size: 1.5em;
  color: #007bff;
  text-align: center;
  margin-top: 50px;
}

.loading-message .icon {
  font-size: 3em;
  margin-bottom: 10px;
}

/* Styles pour le message d'erreur */
.error-message {
  font-size: 1.5em;
  color: #ff4d4d;
  text-align: center;
  margin-top: 50px;
}

.error-message .icon {
  font-size: 3em;
  margin-bottom: 10px;
}
