/* src/pages/Services/Services.css */
.services-section {
    padding: 4rem 2rem;
    background-color: #f7f7f7;
  }
  
  .services-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: #333;
  }
  
  .services-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  